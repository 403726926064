import React from "react";

const Leadpolicy = ({funcNav,funcFooter}) => {
    funcNav(true)
    funcFooter(true)
  return (
    <>
      <section className="blog-banner">
        <div className="container-fluid"></div>
        <div className="col-md-12"></div>
      </section>
      <section className="privacy-main">
        <div className="container">
          <h3>Leadxchange Leads Policy </h3>
          <p>
            Leadxchange lead management process begins with the generation of
            inquiries/leads and is classified into two broad categories
            <br />
            NQL – Nurturing Leads (Partially full fill BANT)
            <br />
            HQL – High Qualified Leads (Full fill BANT)
          </p>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xll-12 ">
              <div className="privacy-inner">
                <div className="table-responsive">
                  <table className="table table-bordere">
                    <tbody>
                      <tr>
                        <th></th>
                        <th>HQL – High Qualified Leads (Fulfill BANT)</th>
                        <th>NQL – Nurturing Leads (Partially fulfill BANT)</th>
                      </tr>
                      <tr>
                        <td>Project Confirmed with Budget</td>
                        <td>Project confirmed with or without budget</td>
                        <td>No confirm project</td>
                      </tr>
                      <tr>
                        <td>Authority</td>
                        <td>Decision makers / Influencers</td>
                        <td>Decision makers / Influencers</td>
                      </tr>
                      <tr>
                        <td>Need</td>
                        <td>
                          Customer Needs and Pain points have been identified.
                        </td>
                        <td>
                          Customer Needs and Pain points have been identified.
                        </td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>Within 6-12 months</td>
                        <td>6 – 24 months</td>
                      </tr>
                      <tr>
                        <td>Solution Discussed</td>
                        <td>Requirement can be met.</td>
                        <td>Requirement can be met.</td>
                      </tr>
                      <tr>
                        <td>Next Steps</td>
                        <td>Demo Request, RFP, Vendor Evaluation</td>
                        <td>Send Information, Events, Marketing Activities</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p>
                  {" "}
                  <strong>Note</strong> – Leadxchange can provide a platform to
                  facilitate sales ,We can generate Qualified leads but will not
                  be involved into Field sales . As per our lead conduit
                  validation policy ,A lead is term "Valid" only if it has no
                  duplicate data , invalid values/ranges and missing fields . A
                  lead is termed "Rejected" only if the mail response from the
                  receipt server fail , We can replace rejected lead only if the
                  same is intimated within 3 working days from the report submission
                  date. Rejected leads can be replaced with fresh leads only
                  thru mail response process, Any Validation of leads through
                  Tele-calling Involves additional cost .
                </p>
                <p>
                  Contacted and Not Contacted leads- – A lead is marked
                  "Contacted" when you call and speak to the lead Contacted
                  leads involve Tele -calling and involves additional cost .
                </p>
                <p>
                  {" "}
                  <strong>Form fills</strong> - Form-fill is auto-accepted,
                  unless there is Red flag raised with respect to: wrong no. /
                  wrong email ID / left organization.
                </p>
                <p>
                If the campaign remains on hold for more than 2 weeks, the client will be liable to pay the setup cost of $1,000. This amount covers the work already undertaken, including account setup on the LeadXchange platform, landing page content creation and Microsite development, conceptualisation and design of EDM content and Design , creation of calling scripts, and other preparatory activities.
                </p>
              
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Leadpolicy;
